import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Header,
  Segment,
  Label,
  Icon,
  Button,
  Grid,
} from "semantic-ui-react"

import { Helmet } from "react-helmet"

import SEO from "../components/seo"

import logo from "../images/progmiscon-logo-64.png"

export default function MisconceptionIFrameTemplate({ pageContext, data }) {
  const { mdx } = data;
  const slug = pageContext.slug;
  const concepts = mdx.frontmatter.concepts || []; 

  return (
    <>
      <Helmet async={false}>
        {/*<!-- You MUST include jQuery before Fomantic -->*/}
        <script src="https://cdn.jsdelivr.net/npm/jquery@3.3.1/dist/jquery.min.js"></script>
        <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/fomantic-ui@2.8.7/dist/semantic.min.css"/>
        <script src="https://cdn.jsdelivr.net/npm/fomantic-ui@2.8.7/dist/semantic.min.js"></script>
        
        <style id='iframe-style' type="text/css">{`
          body {
            background-color: #faefdb;
          }
          div#misconception-body > p > code.language-text {
            background-color: #ffffff40;
            color: white;
          }
        `}</style>
      </Helmet>
      <SEO 
        title={mdx.fields.name} 
        cardTitle={`Misconception: ${mdx.fields.name}`}
        imageRelativeUrl={mdx.fields.image ? mdx.fields.image.childImageSharp.resize.src : null} 
        description={mdx.frontmatter.shortDescription}
        useLargeImageCard={true}
      />
      <div
        style={{
          height: '100%',
          marginTop: "0px",
          marginBottom: "0px",
          background: "#faefdb",
        }}
      >
        <Segment inverted color="red" vertical style={{ padding: "1em 1em" }}>
          <Header as="h1" inverted>
            <Header.Content>
              {mdx.fields.name}
              {mdx.frontmatter.status==="deprecated" ? 
                <Label
                  color="black"
                >
                  Deprecated
                </Label>
                : null
              }
              {mdx.frontmatter.status==="draft" ? 
                <Label
                  color="yellow"
                >
                  DRAFT
                </Label>
                : null
              }
            </Header.Content>
            <Button compact floated='right' color='black' as='a' href={slug} target="_blank">
              <img className='ui avatar image' src={logo} style={{marginRight: '8px'}} />
              Wrong! Why?
              <Icon name="right arrow" />
            </Button>
          </Header>
        </Segment>
        <div style={{padding: '1em'}}>
          <Grid className="very relaxed">
            <Grid.Row>
              <Grid.Column width={8}>
                <div className="ui inverted raised red segment">
                  <div className="ui header">
                    Incorrect
                  </div>
                  <p>
                    {mdx.frontmatter.shortDescription}
                  </p>                        
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div className="ui inverted raised green segment">
                  <div className="ui header">
                    Correct
                  </div>
                  <p>
                    {mdx.frontmatter.shortCorrection}
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {mdx.fields.image ? (
          <Image
            className="ui image"
            fluid={mdx.fields.image.childImageSharp.fluid}
            alt={mdx.fields.name}
            style={{ width: "10242px" }}
          />
        ) : null}
        <Segment
          style={{ padding: "1em 1em", rbackground: "#f2e3be" }}
          vertical
        >
          <Label.Group>
            <Label color="red">{mdx.fields.pl}</Label>
            {concepts.map((c, index) => (
              <Label 
                key={`concepts-${index}`}
                color="brown" 
              >
                {c}
              </Label>
            ))}
          </Label.Group>
        </Segment>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      rawBody
      fields {
        name
        pl
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              src
            }
            resize(width: 1024, height: 512, fit: COVER, cropFocus: NORTH) {
              src
              height
            }
          }
        }
      }
      frontmatter {
        shortDescription
        shortCorrection
        status
        concepts
      }
    }
  
  }
`
